//
// Page of Machine Learning - Python

import React, { Component } from 'react';

class PythonComponent extends Component {
    render() {
        return (
            <div id="pythonPage">
                <h3 className="text-center">Machine Learning in Python</h3>
                <br />
                <hr />
                <section>
                  <p>Under Construction
                  </p>
                </section>
            </div>
        );
    }

}

export default PythonComponent;
